import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import styles from './navi.scss';
export function NaviBar(){
    return(

        <Navbar expand="lg" className="bg-body-tertiary bg-transparent"  >
            <Container>
                <Navbar.Brand href="#home">
                    <img className={styles.logoImg} src={'/assets/black_abstract_logo.png'} alt="alt text" />

                    <div className={styles.content_box2}>
                        <h1 className={styles.title7}>Сборка</h1>
                        <h1 className={styles.title8}>СИМ</h1>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="">
                        <Nav.Link href="http://сборка.квадросим.рф">СБОРЩИК</Nav.Link>
                        <Nav.Link href="#link">РЕГЛАМЕНТ</Nav.Link>
                        <Nav.Link href="#link">РЕЙТИНГ</Nav.Link>

                    </Nav>
                </Navbar.Collapse>
                <img className={styles.telegramImg} src={'/assets/circular_arrow_cursor_icon.png'} alt="alt text" />
            </Container>
        </Navbar>
    );
}