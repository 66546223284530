import React from 'react';
import PropTypes from 'prop-types';


import styles from './index.module.scss';

import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";




function UntitledPage(props) {

  return (




      <Container className={styles.menu}>
        <Row className={styles.menu_row}>
            <Col className= {styles.menu_col}    >     <div className='text-center'> <button  onClick={()=>props.showTable('single-best-time'    )}>   ЛУЧШЕЕ ВРЕМЯ      </button>       </div></Col>
            <Col className= {styles.menu_col}    >     <div className='text-center'> <button  onClick={()=>props.showTable('single-best-score'   )}>   ОЧКИ              </button>        </div></Col>
            <Col  className= {styles.menu_rating}>     <div className='text-center'>                                               РЕЙТИНГ                            </div></Col>
            <Col className= {styles.menu_col}    >     <div className='text-center'> <button  onClick={()=>props.showTable('team-best-time'      )}>   ЛУЧШЕЕ ВРЕМЯ  (К) </button>         </div></Col>
            <Col className= {styles.menu_col}    >     <div className='text-center'> <button  onClick={()=>props.showTable('team-best-score'     )}>   ОЧКИ (К)          </button>        </div></Col>
        </Row>
      </Container>




  );
}

UntitledPage.propTypes = {
  className: PropTypes.string
};


export default UntitledPage;
