import React from 'react';

import styles from './TableSelf.module.scss';

import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";



 function TableSelf(props) {
     if (!props.state.DataisLoaded)

         return (
             <Container className={styles.table}>
             <Row className={styles.table_row} md={3}>


                 <Col md="auto"><h1> {props.state.msg}  </h1></Col>

             </Row>

             <Row className={styles.table_row} md={3}>

                 <Col md="auto" ><img className={styles.logo} src={'/assets/black_abstract_logo.png'} alt="alt text" /></Col>

             </Row>
             </Container>
         );
     console.log(props.state.items);
     const items = props.state.items
     let n = 0;
     return(
         <Container className={styles.table_container}>
         <table >
             <tbody>
                    {

                        items.map((item) => (

                                <tr className={styles.row} style={ n > 0? null : {position:"sticky",top:0,boxShadow:'0 4px 2px -2px gray;'}}>
                                {n > 0? <td><div style={{fontFamily:"Furora",fontWeight:"normal"}}>{n}.</div></td>:null}
                                {
                                    Object.values(item).map((val) => (
                                            <td className={styles.cell} ><div style={{fontFamily:"Furora",fontWeight:"normal"}}>{val}</div></td>
                                        ))
                                }
                                {n++ ? '':''}
                            </tr>

                        ))
                    }
             </tbody>
         </table>
         </Container>
     );


 }
 export default TableSelf;
