import React, { Component } from 'react';
//import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import UntitledPage from './components/UntitledPage';

//import { isMobile } from 'react-device-detect';

import 'aos/dist/aos.css';
import './fonts.css';
import 'components/NaviBar/nav.css';
import {NaviBar} from "./components/NaviBar/NaviBar";
import TableSelf from "./components/Table/TableSelf";
import cn from "classnames";
import styles from "./components/UntitledPage/index.module.scss";


class App extends Component {

  constructor(props) {
    super(props);
    this.showTable('single-best-time');
    this.state = {
      table:'single-best-time',
        items: [],
        DataisLoaded: false,
        msg:'данные загружаются....',
    }
    //this.showTable = this.showTable().bind(this);
  }

  /*componentDidMount() {
    setTimeout(() => {
      AOS.init({
        offset: isMobile ? 10 : 100,
      });

      this.stickey = new Sticky('.sticky-effect');
    }, 1500);
  }

  componentDidUpdate() {
    AOS.refresh();
    if (this.stickey) {
      this.stickey.destory();
      this.stickey = new Sticky('.sticky-effect');
    }
  }
*/


  showTable =(link)=>{
      this.setState({
          DataisLoaded: false,
          msg: 'данные загружаются....',
      });
      fetch("https://constructor-license.yadebil.ru/results/"+link+"/")
          .then((res) => res.json())
          .then((json) => {

              const c  = Object.keys(json[0]).length;
              switch (c) {
                  /*case 4:
                      json.unshift({n:'№', team: 'КОМАНДА', score: 'ОЧКИ', scoring_time: 'СР.ВРЕМЯ', trainings_count: 'ТРЕНИРОВКИ'});break;
                  case 5:
                      json.unshift({n:'№',FIO: 'ФИО', team: 'КОМАНДА', score: 'ОЧКИ', scoring_time: 'ВРЕМЯ', trainings_count: 'ТРЕНИРОВКИ'});break;
                  */
                  case 3:
                      json.unshift({n:'№', team: 'КОМАНДА', score: 'ОЧКИ', scoring_time: 'СР.ВРЕМЯ'});break;
                  case 4:
                      json.unshift({n:'№',FIO: 'ФИО', team: 'КОМАНДА', score: 'ОЧКИ', scoring_time: 'ВРЕМЯ'});break;
                  default:
                      console.log(`Sorry, we are out of ${c}.`);break;
              }

              this.setState({
                  items: json,
                  DataisLoaded: true,
              });
          }).catch((r)=>{
              this.setState({
                DataisLoaded: false,
                msg: 'ошибка при загрузке данных :(',
              });
          });



      //this.setState({table: link})
    return null;
  }
  render() {

    return (
       <div
            className={cn(styles.root)}
            style={{ '--src': `url(${'/assets/033d76c4dd37c103bec7105c373ecdb2.png'})` }}>
         <NaviBar></NaviBar>
        <UntitledPage showTable={this.showTable}></UntitledPage>
         <TableSelf state={this.state} ></TableSelf>
       </div>
    );
  }
}

export default App;
